<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Tabla Dinamica Permisos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Empresas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div class="card">
          <div class="card-header pt-2 pb-2">
            <div class="row">
              <div class="col-md-6">
                <h5>Filtros</h5>
              </div>
            </div>
          </div>
          <div class="card-body">
            <!-- Acordeón de filtros -->
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Seleccionar Rol</label>
                  <select
                    id="roles"
                    v-model="form.roles"
                    required
                    class="form-control form-control-sm p-0"
                    :class="$v.form.roles.$invalid ? 'is-invalid' : 'is-valid'"
                  >
                    <option
                      v-for="role in rolesPage"
                      v-bind:key="role.id"
                      v-bind:value="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-1">
                <button
                  type="button"
                  v-show="!$v.form.$invalid"
                  class="btn bg-navy btn-sm"
                  @click="generarPivotTable()"
                  v-if="$store.getters.can('admin.permisos.pivotTable')"
                >
                  Generar Busqueda
                  <i class="fas fa-search"></i>
                </button>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <pivot-table-component
                      v-if="pivotData"
                      :pivotData="pivotData"
                      :aggregator-name="aggregatorName"
                      :renderer-name="rendererName"
                    >
                    </pivot-table-component>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i>
                  <br />Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PivotTableComponent from "../../../../components/PivotTableComponent.vue";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import { mapActions, mapState } from "vuex";
export default {
  name: "RemolquePivotTable",
  components: {
    PivotTableComponent,
    Loading,
  },
  data() {
    return {
      cargando: false,
      pivotData: [],
      aggregatorName: "Sum",
      rendererName: "Table Heatmap",
      form: {
        roles: null,
      },
      rolesPage: [],
    };
  },
  validations() {
    return {
      form: {
        roles: {
          required,
        },
      },
    };
  },
  beforeMount() {
    this.actGetRoles().then(() => {
      this.rolesPage = this.roles;
    });
  },
  computed: {
    ...mapState("modSeguridad", ["roles"]),
  },
  methods: {
    ...mapActions("modSeguridad", ["actGetRoles"]),
    generarPivotTable() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/permisos/pivotTable",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          this.pivotData = response.data;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
    back() {
      return this.$router.replace("/Admin/Seguridad");
    },
  },
  async mounted() {
    this.cargando = true;
    this.cargando = false;
  },
};
</script>
